import * as React from "react"
import * as css from "./vertical-growth.module.scss"

export const VerticalGrowthCard = (props: {
  img: string
  title: string
  description: string
}) => {
  return (
    <>
      <div className={css.cardHead}>
        <img src={props.img} alt="" />
        <span>{props.title}</span>
      </div>
      <p className={css.cardDescription}>{props.description}</p>
    </>
  )
}
