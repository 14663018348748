import * as React from "react"
import * as css from "./timeline-card.module.scss"
import { ReactElement } from "react"
import classNames from "classnames"

export function TimelineCard(props: {
  className?: string
  children: string | ReactElement
  direction?: "right" | "left"
  color?: "violet" | "pink" | "blue" | "yellow"
}) {
  const directionClasses: Record<string, string> = {
    right: css.right,
    left: css.left,
  }

  const colorClasses: Record<string, string> = {
    violet: css.violet,
    pink: css.pink,
    blue: css.blue,
    yellow: css.yellow,
  }

  return (
    <div
      className={classNames(
        props.color ? css.card : css.cardVacancies,
        props.className,
        directionClasses[props.direction],
        colorClasses[props.color]
      )}
    >
      {props.children}
    </div>
  )
}
