import { CommonLayout } from "../../common/common-layout/common-layout"
import "./../../style/common.scss"
import * as React from "react"
import { OpportunitiesPage } from "../../page-components/career/opportunities/opportunities-page/opportunities-page"

export default function Opportunities(props) {
  return (
    <CommonLayout {...props}>
      <OpportunitiesPage />
    </CommonLayout>
  )
}
