import * as React from "react"
import * as css from "./opportunities-page.module.scss"
import { VerticalGrowth } from "../vertical-growth/vertical-growth"
import { HorizontalGrowth } from "../horizontal-growth/horizontal-growth"
import { CrossFunctionalGrowth } from "../cross-functional-growth/cross-functional-growth"
import classNames from "classnames"
import { useTranslation } from "../../../../common/use-translations/use-translations"
import { CareerNav } from "../../career-nav/career-nav"
import { Helmet } from "react-helmet"

export const OpportunitiesPage = () => {
  const _ = useTranslation();
  return (
    <>
      <Helmet>
        <title>{_.growth_opportunities} - OBRIO</title>
      </Helmet>
      <CareerNav />
      <div className="container">
        <h2 className={css.title}>{_.vertical_growth}</h2>
      </div>
      <VerticalGrowth />

      <div className="container">
        <h2 className={classNames(css.title, css.titleAdaptive)}>
          {_.horizontal_growth}
        </h2>
      </div>
      <HorizontalGrowth />

      <div className="container">
        <h2 className={classNames(css.title, css.titleAdaptive)}>
          {_.crossfunctional_growth}
        </h2>
      </div>
      <CrossFunctionalGrowth />
    </>
  )
}
