import * as React from "react"
import * as css from "./vertical-growth.module.scss"
import { TimelineCard } from "../../../../common/timeline-card/timeline-card"
import { VerticalGrowthCard } from "./vertical-growth-card"
import classNames from "classnames"
import { useTranslation } from "../../../../common/use-translations/use-translations"

export const VerticalGrowth = () => {
  const _ = useTranslation()
  return (
    <section>
      <div className={classNames("container", css.container)}>
        <div className={css.grid}>
          <TimelineCard
            className={classNames(css.card, css.rightC2)}
            direction="right"
            color="blue"
          >
            <VerticalGrowthCard
              img="/opportunities/junior.svg"
              title="Junior"
              description={_.junior_d}
            />
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC2)}
            direction="left"
            color="yellow"
          >
            <VerticalGrowthCard
              img="/opportunities/middle.svg"
              title="Middle"
              description={_.middle_d}
            />
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.rightC1)}
            direction="right"
            color="pink"
          >
            <VerticalGrowthCard
              img="/opportunities/senior.svg"
              title="Senior"
              description={_.senior_d}
            />
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC1)}
            direction="left"
            color="violet"
          >
            <VerticalGrowthCard
              img="/opportunities/lead.svg"
              title="Lead"
              description={_.lead_d}
            />
          </TimelineCard>
        </div>
      </div>
    </section>
  )
}
