import * as React from "react"
import * as css from "./horizontal-growth.module.scss"
import classNames from "classnames"
import { useLocale } from "../../../../common/use-locale/use-locale"

export const HorizontalGrowth = () => {
  const lang = useLocale().locale

  const url = {
    uk: "/opportunities/horizontal-growth.svg",
    en: "/opportunities/horizontal-growth-en.svg",
  }

  const urlSm = {
    uk: "/opportunities/horizontal-growth-mb.svg",
    en: "/opportunities/horizontal-growth-mb-en.svg",
  }

  return (
    <section className={css.section}>
      <div className={classNames("container", "overflow-visible")}>
        <picture>
          <source media="(max-width: 768px)" srcSet={urlSm[lang]} />
          <img className={css.image} src={url[lang]} alt="" />
        </picture>
      </div>
    </section>
  )
}
