import { LocalizedLink } from "../../../common/localized-link/localized-link"
import * as css from "./career-nav.module.scss"
import * as React from "react"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function CareerNav() {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <div className="container">
        <nav className={css.nav}>
          <LocalizedLink activeClassName={css.active} to="/career/benefits">
            {_.benefits}
          </LocalizedLink>
          <LocalizedLink
            activeClassName={css.active}
            to="/career/success-stories"
          >
            {_.success_stories}
          </LocalizedLink>
          <LocalizedLink
            activeClassName={css.active}
            to="/career/opportunities"
          >
            {_.growth_opportunities}
          </LocalizedLink>
        </nav>
      </div>
    </section>
  )
}
